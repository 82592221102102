import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';
import { VoucherModel, VoucherTransformer } from './voucher.model';

@Entity
export class VoucherBySerialNumberModel {
  @Property(z.string())
  type!: string;

  @Property(z.string())
  typeDescription!: string;

  @Property(VoucherTransformer.array())
  vouchers!: VoucherModel[];
}

export const VoucherBySerialNumberTransformer = transformer(VoucherBySerialNumberModel);
