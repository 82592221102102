import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';

@Entity
export class PaymentMethodModel {
  @Property(z.string())
  code!: string;

  @Property(z.string())
  type!: string;

  @Property(z.string().nullable().optional())
  typeCategory?: string;
}

export const PaymentMethodTransformer = transformer(PaymentMethodModel);
