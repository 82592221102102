import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

interface Parameters {
  [key: number]: {
    [key: number]: string;
  };
}

@Injectable()
export class TranslationGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private translateService: TranslateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean {
    const lang = route.paramMap.get('lang');
    let langFromParams;
    let parameters;

    const parametersItem = localStorage.getItem('parameters');
    if (parametersItem !== null) {
      parameters = JSON.parse(parametersItem) as Parameters;
      langFromParams = parameters[1][1];
    }

    if (lang && this.isLangAvailable(lang)) {
      if (lang !== this.translateService.currentLang) {
        this.translateService.resetLang(lang);
        this.translateService.use(lang);
        if (parameters && parameters[1] !== undefined) {
          parameters[1][1] = lang;
          localStorage.setItem('parameters', JSON.stringify(parameters));
        }
      }

      return true;
    }

    const langToRedirect = langFromParams ?? this.translateService.getDefaultLang();

    return this.router.createUrlTree(
      [langToRedirect, ...state.url.slice(1).split('?')[0].split('/')].filter((s) => s.length > 0),
      { queryParams: route.queryParams },
    );
  }

  private isLangAvailable(lang: string): boolean {
    return this.translateService.langs.includes(lang);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean {
    return this.canActivate(childRoute, state);
  }
}
