import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';

export enum Roles {
  ROLE_POS = 'ROLE_POS',
  ROLE_PARTNER = 'ROLE_PARTNER',
  ROLE_SUPERVISOR_THEATER = 'ROLE_SUPERVISOR_THEATER',
  ROLE_HEADQUARTER = 'ROLE_HEADQUARTER',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_SQUAD = 'ROLE_SQUAD',
}
const RoleEnum = z.enum(Object.values(Roles) as [string, ...string[]]);
export type Role = z.infer<typeof RoleEnum>;

export enum Countries {
  NL = 'NL',
  FR = 'FR',
  BE = 'BE',
  CH = 'CH',
}
const CountryEnum = z.enum(Object.values(Countries) as [string, ...string[]]);
export type Country = z.infer<typeof CountryEnum>;

const stringRecord = z.record(z.string());
type StringRecord = z.infer<typeof stringRecord>;

@Entity
export class UserModel {
  @Property(z.string())
  nom!: string;

  @Property(z.string())
  role!: Role;

  @Property(z.string().array())
  countries!: Country[];

  @Property(stringRecord.optional())
  cinemas?: StringRecord;
}

export const UserTransformer = transformer(UserModel);
