import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CORE_CONFIG } from '../tokens';
import { CoreConfig } from '../core.config';
import { IUserService } from '../services';
import { Role } from '../models';

@Injectable()
export class CanActivateIfRoleGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, @Inject(CORE_CONFIG) private config: CoreConfig, private userService: IUserService) {}

  /**
   * Cette guard vérifie que les roles associés à la route contiennent le role de l'utilisateur connecté
   * Si pas de roles spécifiés alors tout le monde est autorisé sur cette route
   * Redirige l'utilisateur vers l'accueil si son role ne correspond pas
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return from(this.userService.me()).pipe(
      map((utilisateur) => {
        const userRole = utilisateur.role;
        const authorizedRoles = route.data['roles'] as Role[] | undefined;

        if (authorizedRoles) {
          if (authorizedRoles.includes(userRole)) {
            return true;
          } else {
            return this.router.createUrlTree(this.config.authRoute);
          }
        }
        return true;
      }),
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): ReturnType<CanActivateIfRoleGuard['canActivate']> {
    return this.canActivate(childRoute, state);
  }
}
