import { IUserHttpService } from '../interfaces';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserResponseBack } from '../interfaces/types';

@Injectable()
export class UserHttpService implements IUserHttpService {
  constructor(private httpClient: HttpClient) {}

  getMe(): Promise<UserResponseBack> {
    return firstValueFrom(this.httpClient.get<UserResponseBack>(`/me`));
  }
}
