import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';
import { VoucherOperationModel, VoucherOperationTransformer } from './voucher-operation.model';

@Entity
export class VoucherHistoryModel {
  @Property(z.string())
  type!: string;

  @Property(z.string())
  typeDescription!: string;

  @Property(z.array(VoucherOperationTransformer))
  history!: VoucherOperationModel[];
}

export const VoucherHistoryTransformer = transformer(VoucherHistoryModel);
