import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { TranslationGuard } from '@pathe-payment/common';
import { CanActivateIfAuthGuard, CanActivateIfNotAuthGuard } from '@pathe-payment/core';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot(
      [
        {
          path: ':lang',
          canActivate: [TranslationGuard],
          children: [
            {
              path: '',
              loadChildren: () => import('./modules/pages/pages.module').then((m) => m.PagesModule),
              canActivate: [CanActivateIfAuthGuard],
            },
            {
              path: 'auth',
              loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
              canActivate: [CanActivateIfNotAuthGuard],
            },
            {
              path: 'errors',
              loadChildren: () => import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
            },
            { path: '**', loadChildren: () => import('./modules/errors/errors.module').then((m) => m.ErrorsModule) },
          ],
        },
        { path: '', component: AppComponent, pathMatch: 'full', canActivate: [TranslationGuard] },
      ],
      {
        preloadingStrategy: PreloadAllModules,
      },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
