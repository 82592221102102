import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from, Observable, switchMap, throwError } from 'rxjs';
import { CORE_CONFIG } from '../tokens';
import { CoreConfig } from '../core.config';
import { AuthService } from '../services';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(@Inject(CORE_CONFIG) private config: CoreConfig, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('/')) {
      request = request.clone({
        url: `${this.config.apiUrl}/api${request.url.endsWith('/me') ? '' : '/v1.0'}${request.url}`,
      });

      return from(this.isAuthenticated()).pipe(
        switchMap((isAuthenticated) => {
          if (!isAuthenticated) {
            return throwError(() => new Error('Not authenticated'));
          }

          if (this.authService.token) {
            request = request.clone({
              headers: request.headers.append('Authorization', `Bearer ${this.authService.token}`),
            });
          }

          return next.handle(request);
        }),
      );
    }

    return next.handle(request);
  }

  private async isAuthenticated(): Promise<boolean> {
    return this.authService.isAuthenticated;
  }
}
