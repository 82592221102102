import { Injectable } from '@angular/core';
import { GroupeParametres, Parametre, ParametreValue } from '../types';
import { distinctUntilChanged, map, Observable, ReplaySubject, shareReplay } from 'rxjs';

const PARAMETERS_KEY = 'parameters';

export type ParameterValue = Parametre['selected'];
type ParameterId = Parametre['id'];
type GroupId = Parametre['id'];

@Injectable()
export class ParametersService {
  private groups = new Map<GroupId, GroupeParametres>();
  private next$ = new ReplaySubject<void>(1);

  get parameters(): Map<ParameterId, Parametre> {
    return new Map(
      [...this.groups.values()]
        .reduce((acc, group) => [...acc, ...group.parametres], [] as Parametre[])
        .map((parametre) => [parametre.id, parametre]),
    );
  }

  get all(): GroupeParametres[] {
    return [...this.groups.values()];
  }

  register(group: GroupeParametres): void {
    const values = this.load();
    group.parametres.forEach((parametre) => {
      const groupeValues = values[group.id] || {};
      const parametreValue = groupeValues[parametre.id];

      if (parametreValue !== undefined) {
        if (parametre.values.length && parametre.selected !== undefined) {
          const parametreValues = parametre.values.map(({ value }) => value);
          const parametreValueArray = Array.isArray(parametreValue) ? parametreValue : [parametreValue];

          if (parametreValueArray.every((value) => parametreValues.includes(value))) {
            parametre.selected = parametreValue;
          }
        } else {
          parametre.selected = parametreValue;
        }
      }

      if (parametre.selected === undefined) {
        parametre.selected = parametre.default;
      }
    });

    this.groups.set(group.id, group);
    this.next$.next();
  }

  setParametreValues(groupId: number, parametreId: number, newValues: ParametreValue[]): void {
    const group = this.groups.get(groupId);
    if (!group) {
      console.error(`Groupe de paramètres ${groupId} non trouvé.`);
      return;
    }

    const parametre = group.parametres.find((p) => p.id === parametreId);
    if (!parametre) {
      console.error(`Paramètre ${parametreId} non trouvé dans le groupe ${groupId}.`);
      return;
    }

    parametre.values = newValues;
    if (!newValues.some((v) => v.value === parametre.selected)) {
      parametre.selected = parametre.default;
    }

    this.save();
    this.next$.next();
  }

  get(id: ParameterId): ParameterValue {
    return this.parameters.get(id)?.selected;
  }

  get$(id: ParameterId): Observable<ParameterValue> {
    return this.next$.pipe(
      map(() => this.get(id)),
      distinctUntilChanged(),
      shareReplay(1),
    );
  }

  set(id: ParameterId, value: ParameterValue): void {
    const parameter = this.parameters.get(id);
    if (parameter) {
      parameter.selected = value;
      this.next$.next();
      this.save();
    }
  }

  private save(): void {
    const values = [...this.groups.values()].reduce(
      (acc, group) => ({
        ...acc,
        [group.id]: group.parametres.reduce(
          (params, parametre) => ({
            ...params,
            [parametre.id]: parametre.selected,
          }),
          {},
        ),
      }),
      {},
    );

    localStorage.setItem(PARAMETERS_KEY, JSON.stringify(values));
  }

  private load(): Record<number, Record<number, ParameterValue>> {
    return JSON.parse(localStorage.getItem(PARAMETERS_KEY) ?? '{}') as Record<number, Record<number, ParameterValue>>;
  }
}
