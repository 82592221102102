import { Injectable } from '@angular/core';

export type CacheKey = string | symbol | number;
export type CachedRecord<O> = { func: () => O; ttl?: number; data?: { value: O; timestamp: number } };

@Injectable()
export class CacheService {
  private cache = new Map<CacheKey, CachedRecord<unknown>>();

  register<O>(key: CacheKey, func: () => O, ttl?: number): void {
    this.cache.set(key, { func, ttl });
  }

  get<O>(key: CacheKey): O {
    const record = this.getRecord<O>(key);
    if (!record.data || (record.ttl && new Date(record.data.timestamp + record.ttl) < new Date())) {
      this.updateCachedValue(key, record.func());
    }

    return record.data!.value;
  }

  clear(key?: CacheKey): void {
    if (key) {
      delete this.getRecord(key).data;
    } else {
      this.cache.forEach((record) => delete record.data);
    }
  }

  has(key: CacheKey): boolean {
    return this.cache.has(key);
  }

  private updateCachedValue<O>(key: CacheKey, value: O): void {
    const record = this.getRecord(key);
    record.data = { value, timestamp: new Date().getTime() };
  }

  private getRecord<O>(key: CacheKey): CachedRecord<O> {
    if (!this.cache.has(key)) {
      throw new Error(`No record for ${String(key)} in cache`);
    }

    return this.cache.get(key) as CachedRecord<O>;
  }
}
