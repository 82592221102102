export abstract class AuthService {
  abstract get isSso(): boolean;

  // abstract get isAuthenticated(): boolean | Promise<boolean>;

  abstract get token(): string;

  abstract authenticate(...args: any[]): Promise<void>;

  abstract get isAuthenticated(): boolean | Promise<boolean>;

  abstract deauthenticate(manual?: boolean): void;
}
