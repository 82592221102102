import { SsoAzureAuthService } from '@pathe-payment/core';
import env from '../../env.json';

export const environment = {
  production: true,
  apiUrl: env.PATHE_PAYMENT_API,
  refreshInterval: 30 * 1000,
  authService: SsoAzureAuthService,
  ssoAzureAuthConfig: {
    clientId: '338e3d4a-21e6-49b1-898b-0fc5a32bcfc9',
    scope: ['api://338e3d4a-21e6-49b1-898b-0fc5a32bcfc9/Payment.API'],
    authority: 'https://login.microsoftonline.com/6643a3bd-8975-46e6-a6ce-1b8025b70944/oauth2/v2.0',
  },
};
