import {
  CardCancellationModel,
  CardConsumptionModel,
  CardModel,
  CardTransformer,
  SessionsModel,
  SessionsTransformer,
} from '../../../models';
import { ICardsService, ICurrentCodeService } from '../interfaces';
import { ConsumptionData, ICardsHttpService } from '../../http';
import { Injectable } from '@angular/core';

@Injectable()
export class CardsService implements ICardsService {
  private lastCard?: CardModel;

  constructor(private cardsHttpService: ICardsHttpService, private currentCodeService: ICurrentCodeService) {}

  async getCard(country: string, numCard: string): Promise<CardModel> {
    const cardBack = await this.cardsHttpService.getCard(country, numCard);

    const card = CardTransformer.parse(cardBack);
    this.lastCard = card;
    this.currentCodeService.setCurrentCode(card.code);
    return card;
  }

  async getLastCard(country: string, cardCode: string): Promise<CardModel> {
    if (this.lastCard?.code === cardCode) {
      return this.lastCard;
    } else {
      return this.getCard(country, cardCode);
    }
  }

  updateLastCard(nbTicketsRemaining: number): void {
    if (this.lastCard) {
      this.lastCard.numberOfSeatsRemaining = nbTicketsRemaining;
    }
  }

  consumeCard(country: string, numCard: string, data: ConsumptionData): Promise<CardConsumptionModel> {
    return this.cardsHttpService.consumeCard(country, numCard, data);
  }

  async getCardSessions(country: string, numCard: string): Promise<SessionsModel> {
    this.currentCodeService.setCurrentCode(numCard);
    const sessionsBack = await this.cardsHttpService.getCardSessions(country, numCard);

    const sessions = SessionsTransformer.parse(sessionsBack);
    return sessions;
  }

  cancelCardOperation(country: string, numCard: string, transactionNumber: number, salesChannel: string): Promise<CardCancellationModel> {
    return this.cardsHttpService.cancelCardOperation(country, numCard, transactionNumber, salesChannel);
  }
}
