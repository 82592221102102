import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { mergeMap, Observable, throwError } from 'rxjs';
import { ERROR_SIMULATION_CONFIG, ErrorSimulationConfig } from '../tokens';
import { ErrorDetail } from './errors.interceptor';

@Injectable()
export class SimulateErrorsInterceptor implements HttpInterceptor {
  // Activer ici pour simuler erreurs http (dev uniquement)
  private active = false;
  constructor(@Inject(ERROR_SIMULATION_CONFIG) private config: ErrorSimulationConfig) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.config.enabled || !this.active) {
      return next.handle(request);
    }

    // Simuler erreur dans requête vouchers
    if (request.url.includes('/vouchers/') && request.method === 'GET') {
      return next.handle(request).pipe(
        mergeMap(() => {
          return throwError(() => ({ status: 400, message: 'Bad Request' } as ErrorDetail));
        }),
      );
    }

    return next.handle(request);
  }
}
