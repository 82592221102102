import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICurrentCodeService } from '../interfaces';

@Injectable()
export class CurrentCodeService implements ICurrentCodeService {
  private currentCode$ = new BehaviorSubject('');

  getCurrentCode(): Observable<string> {
    return this.currentCode$.asObservable();
  }

  setCurrentCode(code: string): void {
    this.currentCode$.next(code);
  }
}
