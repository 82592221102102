import { z } from 'zod';

export const zBoolean = z.boolean().or(z.number().transform((data) => Boolean(data)));
export const zDate = z.date().or(z.string());
export const zDateOptionnal = z.date().or(z.string()).optional();
export const zLocalDate = z.date().or(z.string().transform((val) => new Date(val)));
export const zStringOptionnal = z
  .string()
  .optional()
  .or(z.null().transform(() => undefined));
export const zNumberOptionnal = z
  .number()
  .optional()
  .or(z.null().transform(() => undefined));
export const zIntegerOptionnal = z
  .number()
  .int()
  .optional()
  .or(z.null().transform(() => undefined));
export const zOptionnalMap = z
  .any()
  .transform((data) => {
    const numberMap = new Map<string, number>();
    try {
      for (const [key, value] of Object.entries(data as Map<string, number>)) {
        numberMap.set(key, value as number);
      }
    } catch (err) {
      // console.log('Error while creating zod map : ', err);
    }
    return numberMap;
  })
  .optional()
  .or(z.null().transform(() => undefined));
export const zStringMap = z.any().transform((data) => {
  const stringMap = new Map<string, string>();
  try {
    for (const [key, value] of Object.entries(data as Map<string, string>)) {
      stringMap.set(key, value as string);
    }
  } catch (err) {
    // console.log('Error while creating zod map : ', err);
  }
  return stringMap;
});
