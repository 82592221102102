import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatPrice',
})
export class FormatPricePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(price: number | undefined): string {
    return price ? `${price / 100} €` : '';
  }
}
