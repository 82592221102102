import { Injectable } from '@angular/core';
import { IGlobalStateService } from '@pathe-payment/core';

@Injectable()
export class LoadingService {
  constructor(private globaleStateService: IGlobalStateService) {}

  get loading$() {
    return this.globaleStateService.loading$;
  }

  stopLoading() {
    this.globaleStateService.setLoading(false);
  }
}
