import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';
import { zDate, zDateOptionnal } from '../zod-types';

@Entity
export class VoucherOperationModel {
  @Property(z.string())
  theater!: string;

  @Property(z.string().nullable())
  saleschannel!: string;

  @Property(z.string())
  operationType!: string;

  @Property(zDate)
  operationDateTime!: Date;

  @Property(z.number())
  quantity!: number;

  @Property(z.string().optional().nullable())
  movieTitle?: string;

  @Property(zDateOptionnal.nullable())
  sessionDateTime?: Date;
}

export const VoucherOperationTransformer = transformer(VoucherOperationModel);
