import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthService } from '../services';

@Injectable()
export class CanActivateIfNotAuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate() {
    if (!(await this.authService.isAuthenticated)) {
      return true;
    }

    return this.router.createUrlTree(['/']);
  }

  canActivateChild() {
    return this.canActivate();
  }
}
