import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';

@Entity
export class PriceModel {
  @Property(z.number())
  amount!: number;

  @Property(z.string())
  currency!: string;
}

export const PriceTransformer = transformer(PriceModel);
