import { PaymentMethodModel, PaymentMethodTransformer } from './payment-method.model';
import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';
import { zDateOptionnal } from '../zod-types';
import { PriceModel, PriceTransformer } from './price.model';

@Entity
export class ReportingTransactionsModel {
  @Property(zDateOptionnal)
  dateTimeTransaction!: Date;

  @Property(z.string())
  movieTitle!: string;

  @Property(z.number())
  quantity!: number;

  @Property(zDateOptionnal)
  sessionDateTime!: Date;

  @Property(z.string())
  locationCode!: string;

  @Property(z.string())
  locationName!: string;

  @Property(PaymentMethodTransformer)
  paymentMethod!: PaymentMethodModel;

  @Property(z.string().nullable().optional())
  transactionChannel?: string;

  @Property(z.string().nullable().optional())
  posSystem?: string;

  @Property(PriceTransformer)
  unitPrice!: PriceModel;
}

export const ReportingTransactionsTransformer = transformer(ReportingTransactionsModel);
