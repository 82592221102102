import { Type } from '@angular/core';
import { z, ZodType, ZodTypeDef } from 'zod';
import { ClassProperties } from '../decorators';

export default <T extends object>(type: Type<T>): ZodType<T, ZodTypeDef, unknown> => {
  return getZodSchema(type).transform((data) => {
    type.prototype.constructor.disablePropertyParsing = true;
    try {
      return Object.assign(new type(), data) as T;
    } finally {
      type.prototype.constructor.disablePropertyParsing = false;
    }
  });
};

const getZodSchema = <T>(type: Type<any>) => {
  const properties = ClassProperties.get(type.prototype.constructor);
  if (!properties) {
    throw new Error(`No properties found for ${type.prototype.constructor.name}`);
  }

  return z.object([...properties.entries()].reduce((acc, [key, value]) => Object.assign(acc, { [key]: value }), {}));
};
