import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule, HttpLoaderFactory } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';

import { UiModule } from '@pathe-payment/ui';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@pathe-payment/common';
import { CoreModule } from '@pathe-payment/core';
import { environment } from '../environments/environment';

export function appInitializerFactory(translateService: TranslateService, router: Router): () => Promise<any> {
  return () => {
    const defaultLang = 'fr';
    const supportedLangs = ['fr', 'en'];

    const lang = router.url.split('/')[1];
    const selectedLang = supportedLangs.includes(lang) ? lang : defaultLang;

    return translateService.use(selectedLang).toPromise();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    CommonModule.forRoot({
      langs: ['en', 'fr'],
      defaultLang: 'fr',
    }),
    CoreModule.forRoot({
      authService: environment.authService,
      authRoute: ['/auth'],
      production: environment.production,
      apiUrl: environment.apiUrl,
      ssoAzureAuthConfig: {
        clientId: environment.ssoAzureAuthConfig.clientId,
        scope: environment.ssoAzureAuthConfig.scope,
        authority: environment.ssoAzureAuthConfig.authority,
      },
    }),
    UiModule.forRoot(),
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
