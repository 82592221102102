import { CardCancellationModel, CardConsumptionModel, CardModel, SessionsModel } from '../../../models';
import { ConsumptionData } from '../../http';

export abstract class ICardsService {
  abstract getCard(country: string, numCard: string): Promise<CardModel>;
  abstract getLastCard(country: string, cardCode: string): Promise<CardModel>;
  abstract updateLastCard(nbTicketRemaining: number): void;
  abstract consumeCard(country: string, numCard: string, data: ConsumptionData): Promise<CardConsumptionModel>;
  abstract getCardSessions(country: string, numCard: string): Promise<SessionsModel>;
  abstract cancelCardOperation(
    country: string,
    numCard: string,
    transactionNumber: number,
    salesChannel: string,
  ): Promise<CardCancellationModel>;
}
