<pui-modal *ngIf="groupe" [modalOpen]="true" [closable]="true" (modalOpen)="openChanged.emit($event)">
  <div pui-modal-title>
    <h1>{{ groupe.libelle | translate }}</h1>
  </div>

  <form *ngIf="formGroup" [formGroup]="formGroup" pui-modal-body>
    <ng-container formArrayName="parametres">
      <div *ngFor="let parametre of parametres.controls; let i = index" class="parametre" [formGroupName]="i">
        <h3 *ngIf="groupe.parametres.length > 1">{{ parametre.get('libelle')?.value | translate }} :</h3>

        <ul formArrayName="choices">
          <li
            *ngFor="let choice of getChoices(parametre).controls; let j = index"
            [formGroupName]="j"
            [class.disabled]='choice.get("disabled")?.value'
          >
            <ng-container *ngIf='parametre.get("multi")?.value'>
              <input [id]='"parametre-" + i + "-choix-" + j' type="checkbox" puiCheckbox formControlName="checked" />
            </ng-container>
            <ng-container *ngIf='!parametre.get("multi")?.value'>
              <input
                [id]='"parametre-" + i + "-choix-" + j'
                type="radio"
                puiRadio
                [name]='"parametre-" + i'
                [value]='choice.get("value")?.value'
                [formControl]="getValue(parametre)"
              />
            </ng-container>

            <label [for]='"parametre-" + i + "-choix-" + j'>
              {{ choice.get('libelle')?.value | translate }}
              <ng-container *ngIf='choice.get("precisions")?.value | translate as precisions'>
                <span *ngIf="precisions?.length" class="precisions"> ({{ choice.get('precisions')?.value | translate }}) </span>
              </ng-container>
            </label>
          </li>
        </ul>
      </div>
    </ng-container>
  </form>

  <div *ngIf="!validationAutomatique" pui-modal-footer>
    <pui-button (click)="submit()">{{ 'modals.parametres.valider' | translate }}</pui-button>
  </div>
</pui-modal>
