import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(date: Date, ws = false, separateur = '/'): string {
    const newDate = new Date(date);
    const day = new Date(newDate).getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    const currentLanguage = this.translateService.currentLang;
    const isFrench = currentLanguage === 'fr';

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    if (ws) {
      separateur = '-';
      return `${year}${separateur}${formattedMonth}${separateur}${formattedDay}`;
    } else {
      if (isFrench) {
        return `${formattedDay}${separateur}${formattedMonth}${separateur}${year}`;
      } else {
        return `${formattedMonth}${separateur}${formattedDay}${separateur}${year}`;
      }
    }
  }
}
