import { NgModule } from '@angular/core';

import { SwitchModule } from './switch';
import { ToggleModule } from './toggle';

@NgModule({
  imports: [SwitchModule, ToggleModule],
  exports: [SwitchModule, ToggleModule],
})
export class FormModule {}
