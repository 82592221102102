import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule as NgCommonModule } from '@angular/common';
import { UiModule } from '@pathe-payment/ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { CoreModule } from '@pathe-payment/core';
import { CommonConfig } from './common.config';
import { COMMON_CONFIG } from './tokens';
import * as Guards from './guards';
import * as Services from './services';
import * as Components from './components';
import * as Pipes from './pipes';
import * as Directives from './directives';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FRENCH_DATE_FORMATS, FrenchDateAdapter } from '../utils';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    Components.DatePickerComponent,
    Components.HeaderComponent,
    Components.MenuComponent,
    Components.ParametreModalComponent,
    Components.ParamsInfosComponent,
    Components.StatutComponent,
    Components.ToolbarComponent,
    Directives.DisplayOnDesktopDirective,
    Directives.HideOnDesktopDirective,
    Directives.AutoFocusDirective,
    Pipes.FormatDatePipe,
    Pipes.FormatHourPipe,
    Pipes.FormatPricePipe,
    Pipes.GetCountryNamePipe,
  ],
  imports: [
    NgCommonModule,
    RouterModule,
    UiModule,
    TranslateModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    ReactiveFormsModule,
  ],
  exports: [
    NgCommonModule,
    UiModule,
    CoreModule,
    TranslateModule,
    ReactiveFormsModule,
    Components.DatePickerComponent,
    Components.HeaderComponent,
    Components.MenuComponent,
    Components.StatutComponent,
    Components.ParametreModalComponent,
    Components.ParamsInfosComponent,
    Components.ToolbarComponent,
    Directives.DisplayOnDesktopDirective,
    Directives.AutoFocusDirective,
    Directives.HideOnDesktopDirective,
    Pipes.FormatDatePipe,
    Pipes.FormatHourPipe,
    Pipes.FormatPricePipe,
    Pipes.GetCountryNamePipe,
  ],
})
export class CommonModule {
  static forRoot(config: CommonConfig): ModuleWithProviders<CommonModule> {
    return {
      ngModule: CommonModule,
      providers: [
        {
          provide: COMMON_CONFIG,
          useValue: config,
        },
        { provide: DateAdapter, useClass: FrenchDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: FRENCH_DATE_FORMATS },
        Guards.TranslationGuard,
        Pipes.FormatDatePipe,
        Pipes.FormatHourPipe,
        Pipes.FormatPricePipe,
        Services.CountriesService,
        Services.LoadingService,
        Services.NavigationService,
        Services.ParametersService,
        Services.VersionService,
        Services.DateUtilsService,
      ],
    };
  }

  constructor(@Inject(COMMON_CONFIG) config: CommonConfig, translateService: TranslateService) {
    translateService.addLangs(config.langs);
    translateService.setDefaultLang(config.defaultLang);
  }
}
