import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { providers } from './providers';
import * as Components from './components';

@NgModule({
  imports: [CommonModule],
  declarations: [Components.ToolbarComponent, Components.ToolbarItemComponent],
  exports: [Components.ToolbarComponent, Components.ToolbarItemComponent],
})
export class ToolbarModule {
  static forRoot(): ModuleWithProviders<ToolbarModule> {
    return {
      ngModule: ToolbarModule,
      providers,
    };
  }
}
