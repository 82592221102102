import { Observable } from 'rxjs';
import { IGlobalStateService } from '../interfaces';

export class GlobalStateService extends IGlobalStateService {
  get loading$(): Observable<boolean> {
    return this.loadingSubject$.asObservable();
  }

  setLoading(isLoading: boolean): void {
    this.loadingSubject$.next(isLoading);
  }
}
