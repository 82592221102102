import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pui-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('insertRemoveDialog', [
      transition(':enter', [
        style({ opacity: 0.5, transform: 'translateY(10vh)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
    ]),
    trigger('insertRemoveBackdrop', [transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 0.7 }))])]),
  ],
})
export class ModalComponent {
  @Input() closable = true;
  @Input() backdrop = true;
  @Output('modalOpen') openChanged = new EventEmitter<boolean>();

  @ViewChild('dialog') dialog!: ElementRef<HTMLDivElement>;
  @ViewChild('backdrop') backdropRef!: ElementRef<HTMLDivElement>;

  private isOpened?: boolean;
  altClose = this.translateService.currentLang === 'en' ? 'Close' : 'Fermer';

  constructor(private translateService: TranslateService) {}

  get isOpen(): boolean {
    return !!this.isOpened;
  }

  @Input('modalOpen')
  set isOpen(value: boolean) {
    this.isOpened = value;
    this.openChanged.emit(this.isOpened);
  }

  @HostBinding('style.display')
  get display(): string {
    return this.isOpen ? 'flex' : 'none';
  }

  @HostListener('document:click', ['$event.target'])
  onDocumentClick(targetElement: HTMLElement) {
    if (this.isOpen && this.closable) {
      const clickOutside = this.backdropRef.nativeElement === targetElement || this.backdropRef.nativeElement.contains(targetElement);
      if (clickOutside) {
        this.isOpen = false;
      }
    }
  }

  @HostListener('window:keyup.esc')
  keyEvent() {
    if (this.isOpen && this.closable) {
      this.isOpen = false;
    }
  }
}
