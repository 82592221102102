import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../interfaces';
import { CORE_CONFIG } from '../../../tokens';
import { CoreConfig } from '../../../core.config';
import { CacheService } from '../../misc';
import { IUserService } from '../../public';

@Injectable()
export class SsoAzureAuthService extends AuthService {
  readonly isSso = true;

  private get accessToken(): string {
    return localStorage.getItem('access_token') as string;
  }

  private set accessToken(value: string) {
    localStorage.setItem('access_token', value);
  }

  private get refreshToken(): string {
    return localStorage.getItem('refresh_token') as string;
  }

  private set refreshToken(value: string) {
    localStorage.setItem('refresh_token', value);
  }

  private get accessTokenExpireAt(): Date {
    return new Date(localStorage.getItem('access_token_expire_at') as string);
  }

  private set accessTokenExpireAt(value: Date) {
    localStorage.setItem('access_token_expire_at', value.toUTCString());
  }

  private get codeVerifier(): string {
    return localStorage.getItem('pkce_code_verifier') as string;
  }

  private set codeVerifier(value: string) {
    localStorage.setItem('pkce_code_verifier', value);
  }

  private get state(): string {
    return localStorage.getItem('pkce_state') as string;
  }

  private set state(value: string) {
    localStorage.setItem('pkce_state', value);
  }

  private get failedAttempts(): number {
    return +(localStorage.getItem('failed_attempts') ?? 0);
  }

  private set failedAttempts(value: number) {
    if (value) {
      localStorage.setItem('failed_attempts', value.toString());
    } else {
      localStorage.removeItem('failed_attempts');
    }
  }

  private get redirectUri(): string {
    return `${location.protocol}//${location.host}${this.router.createUrlTree(this.config.authRoute).toString()}`;
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    @Inject(CORE_CONFIG) private config: CoreConfig,
    private httpClient: HttpClient,
    private cacheService: CacheService,
    private userService: IUserService,
  ) {
    super();

    if (!this.config || !this.config.ssoAzureAuthConfig) {
      throw new Error('SSO configuration is missing');
    }
  }

  get isAuthenticated(): boolean | Promise<boolean> {
    if (this.accessToken) {
      if (this.isTokenExpired) {
        return new Promise<boolean>(async (resolve) => {
          try {
            await this.getAccessToken(this.refreshToken, true);
          } catch (_) {
            this.deauthenticate();
          } finally {
            resolve(!!this.accessToken);
          }
        });
      }

      return true;
    }

    return false;
  }

  get isTokenExpired(): boolean {
    return this.accessTokenExpireAt < new Date();
  }

  get token(): string {
    return this.accessToken;
  }

  async authenticate() {
    try {
      if (!this.getRouteParam('code')) {
        if (this.getRouteParam('error')) {
          this.failedAttempts++;
          throw new Error(this.getRouteParam('error_description') as string);
        }

        this.failedAttempts = 0;
        await this.authorize('none');
      } else {
        if (this.state !== this.getRouteParam('state')) {
          this.failedAttempts++;
          throw new Error('Invalid state');
        }

        await this.getAccessToken(this.getRouteParam('code') as string);

        try {
          await this.userService.me();
          this.failedAttempts = 0;
        } catch (e) {
          this.failedAttempts++;
        }
      }
    } catch (e) {
      throw e;
    } finally {
      if (this.failedAttempts === 1) {
        await this.authorize('select_account');
      }
    }
  }

  async deauthenticate(manual = false) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token_expire_at');

    try {
      this.cacheService.clear();

      if (manual) {
        const postLogoutRedirectUri = encodeURIComponent(`${window.location.protocol}//${window.location.host}/auth?disconnected=true`);
        const logoutUrl = `${this.config.ssoAzureAuthConfig!.authority}/logout?post_logout_redirect_uri=${postLogoutRedirectUri}`;
        window.location.href = logoutUrl;
      } else {
        await this.router.navigate(this.config.authRoute, { queryParams: { disconnected: true } });
      }
    } catch (e) {}
  }

  private async authorize(prompt: 'none' | 'select_account') {
    this.state = this.generateRandomString();
    this.codeVerifier = this.generateRandomString();

    const queryParams: Record<string, string> = {
      client_id: this.config.ssoAzureAuthConfig!.clientId,
      response_type: 'code',
      prompt,
      scope: this.config.ssoAzureAuthConfig!.scope.join(' '),
      redirect_uri: this.redirectUri,
      response_mode: 'query',
      state: this.state,
      code_challenge_method: 'S256',
      code_challenge: await this.pkceChallengeFromVerifier(this.codeVerifier),
    };

    const params = this.buildQueryParam(queryParams);
    location.replace(`${this.config.ssoAzureAuthConfig!.authority}/authorize?${encodeURI(params)}`);
  }

  private async getAccessToken(code: string, refresh?: boolean) {
    const queryParams = new Map<string, any>([
      ['grant_type', refresh ? 'refresh_token' : 'authorization_code'],
      ['client_id', this.config.ssoAzureAuthConfig!.clientId],
      ['scope', this.config.ssoAzureAuthConfig!.scope.join(' ')],
    ]);

    if (refresh) {
      queryParams.set('refresh_token', code);
    } else {
      queryParams.set('code', code);
      queryParams.set('code_verifier', this.codeVerifier);
      queryParams.set('redirect_uri', this.redirectUri);
    }

    const params = this.buildQueryParam(Object.fromEntries(queryParams));
    try {
      const response: any = await this.httpClient
        .post(`${this.config.ssoAzureAuthConfig!.authority}/token`, encodeURI(params), {
          headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        })
        .toPromise();

      this.accessToken = response.access_token;
      this.refreshToken = response.refresh_token;
      this.accessTokenExpireAt = new Date(Date.now() + Number(response.expires_in) * 1000);
    } catch (e: any) {
      if (e.error) {
        throw new Error(e.error_description);
      }
      throw new Error('Unknown error');
    }
  }

  private buildQueryParam(queryParams: { [key: string]: any }): string {
    return Reflect.ownKeys(queryParams)
      .reduce((acc: string, key: string | symbol) => `${acc}&${String(key)}=${Reflect.get(queryParams, key)}`, '')
      .substr(1);
  }

  private generateRandomString(): string {
    const array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join('');
  }

  private async pkceChallengeFromVerifier(verifier: string): Promise<string> {
    return this.base64urlencode(await this.sha256(verifier));
  }

  private sha256(plain: string): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
  }

  private base64urlencode(buffer: ArrayBuffer): string {
    // @ts-ignore
    return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  private parseJwt(token: string): Record<string, any> {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  private getRouteParam(key: string): string | null {
    return this.router.routerState.snapshot.root.queryParamMap.get(key);
  }
}
