import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as Components from './components';

@NgModule({
  imports: [CommonModule],
  declarations: [Components.CellComponent, Components.RowComponent, Components.TableComponent],
  exports: [Components.CellComponent, Components.RowComponent, Components.TableComponent],
})
export class TableModule {}
