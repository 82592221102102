import { Observable } from 'rxjs';
import { PartnersReportingBack, ReportingTransactionsBack } from './types';

export abstract class IReportingHttpService {
  abstract getReportingPartners(
    country: string,
    startDate: string,
    endDate: string,
    codeCinema?: string,
  ): Observable<PartnersReportingBack>;
  abstract getReportingTransactions(country: string, startDate: string, endDate: string): Promise<ReportingTransactionsBack>;
}
