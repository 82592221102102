import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';
import { ReportingSessionModel, ReportingSessionTransformer } from './reporting-session.model';

@Entity
export class ReportingPartnersModel {
  @Property(z.string())
  locationCode!: string;

  @Property(ReportingSessionTransformer.array())
  sessions!: ReportingSessionModel[];
}

export const ReportingPartnersTransformer = transformer(ReportingPartnersModel);
