import {
  ApplicationRef,
  ComponentFactoryResolver,
  Directive,
  EmbeddedViewRef,
  EventEmitter,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  Type,
} from '@angular/core';
import { filter } from 'rxjs';

export const MODAL_DATA = new InjectionToken('Modal data');

@Directive()
export abstract class ModalComponentInterface<D> {
  abstract openChanged: EventEmitter<boolean>;

  constructor(@Inject(MODAL_DATA) protected data: D) {}
}

@Injectable()
export class ModalManagerService {
  constructor(
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) {}

  openModal<T extends ModalComponentInterface<D>, D>(modalType: Type<T>, data?: D): T {
    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: MODAL_DATA, useValue: data }],
    });

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modalType);
    const component = componentFactory.create(injector);

    this.applicationRef.attachView(component.hostView);
    component.onDestroy(() => this.applicationRef.detachView(component.hostView));

    const componentRootNode = (component.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
    document.body.appendChild(componentRootNode);

    const subscription = component.instance.openChanged.pipe(filter((isOpen) => !isOpen)).subscribe(() => {
      subscription?.unsubscribe();
      component.destroy();
    });

    return component.instance;
  }
}
