import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';
import { zDate } from '../zod-types';
import { PriceModel, PriceTransformer } from './price.model';

@Entity
export class GiftCardModel {
  @Property(z.string())
  code!: string;

  @Property(zDate.nullable())
  expirationDate?: Date;

  @Property(PriceTransformer)
  price!: PriceModel;

  @Property(z.string())
  status!: string;
}

export const GiftCardTransformer = transformer(GiftCardModel);
