// eslint-disable-next-line @typescript-eslint/ban-types
import { ClassProperties } from './property.decorator';

// eslint-disable-next-line @typescript-eslint/ban-types
export const Entity: ClassDecorator = <TFunction extends Function>(constructor: TFunction): TFunction => {
  const newConstructor = function (...args: any[]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const obj = new constructor(...args);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return new Proxy(obj, {
      set: (obj: any, prop: string, value: any): boolean => {
        const zodType = ClassProperties.get(constructor)?.get(prop);

        // @ts-ignore
        if (!constructor.disablePropertyParsing && zodType) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          obj[prop] = zodType.parse(value);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          obj[prop] = value;
        }

        return true;
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  newConstructor.prototype = constructor.prototype;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return newConstructor;
};
