import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ParametersService } from '@pathe-payment/common';
import { IUserService, Country } from '@pathe-payment/core';
import { Groupes, Parametres, allAvailableCountries } from 'src/environments/parametres';

@Injectable()
export class CountriesService implements OnDestroy {
  private subscription = new Subscription();

  constructor(private userService: IUserService, private parametersService: ParametersService) {}

  loadCountriesData(): void {
    this.subscription.add(this.userService.userCountries$.subscribe((countries: Country[]) => this.updateCountriesForUser(countries)));
  }

  private updateCountriesForUser(countries: Country[]): void {
    const userCountries = allAvailableCountries.filter((country) => countries.includes(country.value as Country));
    this.parametersService.setParametreValues(Groupes.PAYS, Parametres.PAYS, userCountries);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
