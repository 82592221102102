import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModalComponentInterface } from '@pathe-payment/ui';
import { GroupeParametres, Parametre, ParametreValue } from '../../types';

@Component({
  selector: 'ppa-common-parametre-modal',
  templateUrl: './parametre-modal.component.html',
  styleUrls: ['./parametre-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParametreModalComponent<O = ParametreValue['value']>
  extends ModalComponentInterface<{ groupe: GroupeParametres }>
  implements OnInit, OnDestroy
{
  @Output() openChanged = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<Map<number, O | O[]>>();

  formGroup?: UntypedFormGroup;

  get groupe(): GroupeParametres {
    return this.data.groupe;
  }

  get parametres(): UntypedFormArray {
    return this.formGroup?.get('parametres') as UntypedFormArray;
  }

  get validationAutomatique(): boolean {
    return this.groupe.parametres.length === 1 && this.groupe.parametres.every((parametre) => !parametre.multi);
  }

  private subscriptions = new Subscription();

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      parametres: new UntypedFormArray(this.groupe.parametres.map((parametre) => this.parametreToForm(parametre))),
    });

    if (this.validationAutomatique) {
      this.subscriptions.add(this.formGroup.valueChanges.subscribe(() => this.submit()));
    }

    if (this.groupe.disabled) {
      this.formGroup.disable();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getChoices(parametre: AbstractControl): UntypedFormArray;
  getChoices(parametre: UntypedFormGroup): UntypedFormArray {
    return parametre.get('choices') as UntypedFormArray;
  }

  getValue(parametre: AbstractControl): UntypedFormControl;
  getValue(parametre: UntypedFormGroup): UntypedFormControl {
    return parametre.get('value') as UntypedFormControl;
  }

  submit() {
    if (this.formGroup?.valid) {
      const values = this.parametres.value as {
        id: number;
        libelle: string;
        multi?: boolean;
        value: O;
        choices: { value: O; checked: boolean }[];
      }[];

      this.save.emit(
        new Map(
          values.map((value) => {
            if (value.multi) {
              return [value.id, value.choices.filter(({ checked }) => checked).map(({ value }) => value)];
            }

            return [value.id, value.value];
          }) as [number, O | O[]][],
        ),
      );

      this.openChanged.emit(false);
    }
  }

  private parametreToForm(parametre: Parametre): AbstractControl {
    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(parametre.id),
      libelle: new UntypedFormControl(parametre.libelle),
      multi: new UntypedFormControl(parametre.multi),
    });

    if (parametre.multi) {
      formGroup.addControl(
        'choices',
        new UntypedFormArray(
          parametre.values.map(
            (value) =>
              new UntypedFormGroup({
                libelle: new UntypedFormControl(value.libelle),
                precisions: new UntypedFormControl(value.precisions),
                value: new UntypedFormControl(value.value),
                checked: new UntypedFormControl(parametre.selected?.includes(value.value)),
                disabled: new UntypedFormControl(value.disabled),
              }),
          ),
        ),
      );
    } else {
      formGroup.addControl('value', new UntypedFormControl(parametre.selected, void Validators.required));
      formGroup.addControl(
        'choices',
        new UntypedFormArray(
          parametre.values.map(
            (value) =>
              new UntypedFormGroup({
                libelle: new UntypedFormControl(value.libelle),
                precisions: new UntypedFormControl(value.precisions),
                value: new UntypedFormControl(value.value),
                disabled: new UntypedFormControl(value.disabled),
              }),
          ),
        ),
      );
    }

    return formGroup;
  }
}
