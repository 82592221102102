import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';

import { providers } from './providers';
import * as Directives from './directives';

@NgModule({
  imports: [CommonModule],
  declarations: [Directives.NgIfOrientationDirective],
  exports: [Directives.NgIfOrientationDirective],
})
export class OrientationModule {
  static forRoot(): ModuleWithProviders<OrientationModule> {
    return {
      ngModule: OrientationModule,
      providers,
    };
  }
}
