import { Injectable } from '@angular/core';
import packageJson from 'package.json';

@Injectable()
export class VersionService {
  private regex = /^(\d+)\.(\d+)\.(\d+)(?:-([\dA-Za-z-]+(?:\.[\dA-Za-z-]+)*))?(?:\+([\dA-Za-z-.]+))?$/;
  readonly version = packageJson.version;

  readonly major!: number;
  readonly minor!: number;
  readonly patch!: number;
  readonly identifiers!: string[];
  readonly build?: number;

  constructor() {
    const matches = this.regex.exec(this.version);
    if (matches) {
      this.major = Number(matches[1]);
      this.minor = Number(matches[2]);
      this.patch = Number(matches[3]);
      this.identifiers = matches[4]?.split('.');
      this.build = matches[5] ? Number(matches[5]) : undefined;
    }
  }

  get uniqueIdentifier(): number {
    return [this.build ?? 0, this.patch, this.minor, this.major].reduce((acc, item, index) => acc + item * Math.pow(10, index * 2), 0);
  }
}
