import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';
import { SessionTransactionModel, SessionTransactionTransformer } from './session-transaction.model';

@Entity
export class SessionsModel {
  @Property(z.string())
  code!: string;

  @Property(z.array(SessionTransactionTransformer))
  sessionTransactions!: SessionTransactionModel[];
}

export const SessionsTransformer = transformer(SessionsModel);
