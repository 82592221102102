import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from, map, Observable } from 'rxjs';
import { IRoleOverrideService, IUserService } from '../interfaces';
import { CacheService } from '../../misc';
import { IUserHttpService } from '../../http';
import { Country, Role, Roles, UserModel, UserTransformer } from '../../../models';

enum UserCacheKeys {
  ME = 'USER_ME',
}

@Injectable()
export class UserService implements IUserService {
  public userCountries$: BehaviorSubject<Country[]> = new BehaviorSubject([] as Country[]);
  private primaryRole?: Role;

  constructor(
    private userHttpService: IUserHttpService,
    private cacheService: CacheService,
    private roleOverrideService: IRoleOverrideService,
  ) {
    const loadMe = async (): Promise<UserModel> => {
      const userBack = await this.userHttpService.getMe();
      const user = UserTransformer.parse(userBack);
      this.primaryRole = user.role;
      this.userCountries$.next(user.countries);
      return user;
    };

    this.cacheService.register(UserCacheKeys.ME, loadMe);
  }

  me(): Observable<UserModel> {
    try {
      const user$ = from(this.cacheService.get<Promise<UserModel>>(UserCacheKeys.ME));
      const overriddenRole$ = this.roleOverrideService.getOverriddenRole$();

      return combineLatest([user$, overriddenRole$]).pipe(
        map(([user, overriddenRole]) => {
          return { ...user, role: overriddenRole && user?.role === Roles.ROLE_ADMIN ? overriddenRole : (this.primaryRole as Role) };
        }),
      );
    } catch (e) {
      this.cacheService.clear(UserCacheKeys.ME);
      throw e;
    }
  }
}
