import { z } from 'zod';

export const ClassProperties = new Map<unknown, Map<string | symbol, z.ZodType<unknown>>>();

export function Property<T>(zodType: z.ZodType<T>): PropertyDecorator {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return function (target: Object, propertyKey: string | symbol): void {
    if (!ClassProperties.has(target.constructor)) {
      ClassProperties.set(target.constructor, new Map<string, z.ZodType<unknown>>());
    }

    ClassProperties.get(target.constructor)?.set(propertyKey, zodType);
  };
}
