import {
  VoucherCancellationResponseBack,
  VoucherConsumptionResponseBack,
  VoucherHistoryResponseBack,
  VoucherResponseBack,
  SessionsResponseBack,
  VoucherBySerialNumberResponseBack,
  GiftCardResponseBack,
} from './types';

export abstract class IVouchersHttpService {
  abstract getVoucher(country: string, numCard: string): Promise<VoucherResponseBack>;
  abstract getVoucherHistory(country: string, numCard: string): Promise<VoucherHistoryResponseBack>;
  abstract getVoucherSessions(country: string, numCard: string): Promise<SessionsResponseBack>;
  abstract consumeVoucher(country: string, numCard: string, data: ConsumptionData): Promise<VoucherConsumptionResponseBack>;
  abstract cancelVoucherOperation(
    country: string,
    numCard: string,
    transactionNumber: number,
    salesChannel: string,
  ): Promise<VoucherCancellationResponseBack>;
  abstract getVoucherBySerialNumber(
    country: string,
    type: string,
    serialNumberStart: number,
    serialNumberEnd?: number,
  ): Promise<VoucherBySerialNumberResponseBack>;
  abstract getGiftCard(country: string, numCard: string, pin: number): Promise<GiftCardResponseBack>;
}

export interface ConsumptionData {
  salesChannel: string;
  movieTitle: string;
  governmentMovieCode: string | null;
  sessionDateTime: string;
  quantity: number;
}
