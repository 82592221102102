import { firstValueFrom, Observable } from 'rxjs';
import { ReportingTransactionsBack, PartnersReportingBack } from '../interfaces/types';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IReportingHttpService } from '../interfaces';

@Injectable()
export class ReportingHttpService implements IReportingHttpService {
  constructor(protected httpClient: HttpClient) {}

  getReportingPartners(country: string, startDate: string, endDate: string, codeCinema = 'HO'): Observable<PartnersReportingBack> {
    return this.httpClient.get<PartnersReportingBack>(`/reporting/partners/${codeCinema}`, { params: { country, startDate, endDate } });
  }

  getReportingTransactions(country: string, startDate: string, endDate: string): Promise<ReportingTransactionsBack> {
    return firstValueFrom(
      this.httpClient.get<ReportingTransactionsBack>(`/reporting/advanced`, { params: { country, startDate, endDate } }),
    );
  }
}
