import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Role, Roles } from '../../../models';
import { IRoleOverrideService } from '../interfaces';

@Injectable()
export class RoleOverrideService implements IRoleOverrideService {
  protected readonly STORAGE_KEY = 'overridden_role';
  protected overriddenRole$ = new BehaviorSubject<Role | null>(this.getStoredRole());

  getOverriddenRole$(): Observable<Role | null> {
    return this.overriddenRole$.asObservable();
  }

  setOverriddenRole(role: Role | null): void {
    this.overriddenRole$.next(role);
    if (role) {
      localStorage.setItem(this.STORAGE_KEY, role);
    } else {
      localStorage.removeItem(this.STORAGE_KEY);
    }
  }

  private getStoredRole(): Role | null {
    const storedRole = localStorage.getItem(this.STORAGE_KEY) as Role;
    return storedRole && Object.values(Roles).includes(storedRole as Roles) ? storedRole : null;
  }
}
