// src/app/services/date.service.ts

import { Injectable } from '@angular/core';

export interface MonthDateRange {
  startDate: Date;
  endDate: Date;
}

@Injectable()
export class DateUtilsService {
  /**
   * Retourne le premier et le dernier jour du dernier mois achevé.
   */
  getLastCompletedMonth(): MonthDateRange {
    const today = new Date();
    const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfLastMonth = new Date(firstDayOfCurrentMonth.getTime() - 1);
    const startDate = new Date(lastDayOfLastMonth.getFullYear(), lastDayOfLastMonth.getMonth(), 1);
    const endDate = new Date(lastDayOfLastMonth.getFullYear(), lastDayOfLastMonth.getMonth() + 1, 0);
    return { startDate, endDate };
  }
}
