<div *ngIf="backdrop || closable" #backdrop id="backdrop" @insertRemoveBackdrop></div>
<div #dialog id="dialog" @insertRemoveDialog>
  <img *ngIf="closable" [alt]="altClose" class="close" (click)="isOpen = false" role="button" aria-pressed="false" src="assets/svg/icons/Close.svg" />
  <div id="title-wrapper">
    <ng-content select="[pui-modal-title]"></ng-content>
  </div>
  <div id="body-wrapper">
    <ng-content select="[pui-modal-body]"></ng-content>
  </div>
  <div id="footer-wrapper">
    <ng-content select="[pui-modal-footer]"></ng-content>
  </div>
</div>
