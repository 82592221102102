import { Injectable } from '@angular/core';
import { NativeDateAdapter, MatDateFormats } from '@angular/material/core';

@Injectable()
export class FrenchDateAdapter extends NativeDateAdapter {
  override getFirstDayOfWeek(): number {
    if (this.locale === 'fr') {
      return 1;
    } else {
      return 0;
    }
  }
}

export const FRENCH_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
