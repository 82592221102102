import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatHour',
})
export class FormatHourPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(date: Date): string {
    const newDate = new Date(date);
    const hours = newDate.getHours();
    const minutes = newDate.getMinutes();

    const currentLanguage = this.translateService.currentLang;
    const isFrench = currentLanguage === 'fr';

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    if (isFrench) {
      return `${formattedHours}h${formattedMinutes}`;
    } else {
      return `${formattedHours}:${formattedMinutes}`;
    }
  }
}
