import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';
import { zDate } from '../zod-types';

@Entity
export class SessionTransactionModel {
  @Property(z.number())
  consumeTransactionId!: number;

  @Property(z.string())
  movieTitle!: string;

  @Property(zDate)
  sessionDateTime!: Date;

  @Property(z.string().nullable())
  locationCode!: string;

  @Property(z.number())
  quantity!: number;

  @Property(z.string().nullable().optional())
  theaterName?: string;

  @Property(zDate)
  transactionDateTime!: Date;
}

export const SessionTransactionTransformer = transformer(SessionTransactionModel);
