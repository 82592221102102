import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';
import { zDateOptionnal } from '../zod-types';

@Entity
export class CardMemberModel {
  @Property(z.string())
  id!: string;

  @Property(z.string().nullable().optional())
  firstName?: string;

  @Property(z.string())
  lastName!: string;

  @Property(zDateOptionnal.nullable())
  birthDate?: Date;

  @Property(z.string().nullable().optional())
  photo?: string;
}

export const CardMemberTransformer = transformer(CardMemberModel);
