import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';
import { PriceModel, PriceTransformer } from './price.model';
import { CardMemberModel, CardMemberTransformer } from './card-member.model';

@Entity
export class CardModel {
  @Property(z.string())
  code!: string;

  @Property(z.string())
  clubDescription!: string;

  @Property(z.boolean().optional())
  invoiceDebit?: boolean;

  @Property(CardMemberTransformer)
  member!: CardMemberModel;

  @Property(z.number().nullable().optional())
  quantityToTake?: number;

  @Property(PriceTransformer)
  price!: PriceModel;

  @Property(z.string())
  status!: string;

  @Property(z.string().nullable().optional())
  type?: string;

  @Property(z.number().optional())
  numberOfSeatsRemaining?: number;
}

export const CardTransformer = transformer(CardModel);
