import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, distinctUntilChanged, map, startWith } from 'rxjs';

@Injectable()
export class NavigationService {
  readonly routerData$ = this.router.events.pipe(
    startWith(() => this.getRouterData()),
    map(() => this.getRouterData()),
    distinctUntilChanged(),
  );
  displayMenu$ = new BehaviorSubject(false);

  private history: string[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects.split('/').slice(2).join('/');
        let giftCard = false;

        if (event.urlAfterRedirects.includes('information')) {
          const code = event.urlAfterRedirects.slice(event.urlAfterRedirects.lastIndexOf('/') + 1);
          giftCard = code.length > 12;
        }

        const fromNoMenu =
          this.history[this.history.length - 1] === undefined ||
          this.history[this.history.length - 1] === '' ||
          this.history[this.history.length - 1] === 'reporting' ||
          this.history[this.history.length - 1] === 'reporting-transactions';
        if (this.history[this.history.length - 1] !== url) {
          this.history.push(url);
          if (['', 'reporting', 'reporting-transactions', 'login'].includes(url) || (url === 'parametres' && fromNoMenu) || giftCard) {
            this.displayMenu$.next(false);
          } else {
            this.displayMenu$.next(true);
          }
        }
      }
    });
  }

  async back(): Promise<void> {
    this.history.pop();
    await this.router.navigateByUrl(this.history[this.history.length - 1] ?? '/');
  }

  getRouterData(): Data {
    const data: Data = {};

    let child = this.activatedRoute.firstChild;
    while (child) {
      if (child.snapshot.data) {
        Object.assign(data, child.snapshot.data);
      }

      child = child.firstChild;
    }

    return data;
  }

  getDisplayMenu(): BehaviorSubject<boolean> {
    return this.displayMenu$;
  }
}
