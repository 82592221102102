import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';
import { zDate, zDateOptionnal } from '../zod-types';
import { PriceModel, PriceTransformer } from './price.model';

@Entity
export class VoucherModel {
  @Property(z.string())
  code!: string;

  @Property(zDate.nullable().optional())
  expirationDate?: Date;

  @Property(z.number().nullable().optional())
  numberOfSeatsRemaining?: number;

  @Property(PriceTransformer.nullable().optional())
  price?: PriceModel;

  @Property(z.number().nullable().optional())
  serialNumber?: number;

  @Property(z.string().nullable().optional())
  status?: string;

  @Property(zDateOptionnal.nullable().optional())
  validityStartDate?: Date;

  @Property(z.string().nullable().optional())
  type?: string;

  @Property(z.string().nullable().optional())
  typeDescription?: string;

  @Property(z.string().nullable().optional())
  blacklistingUnblacklistingReason?: string;
}

export const VoucherTransformer = transformer(VoucherModel);
