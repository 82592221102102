import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@pathe-payment/core';
import { VersionService } from '@pathe-payment/common';

@Component({
  selector: 'ppa-common-params-infos',
  templateUrl: './params-infos.component.html',
  styleUrls: ['./params-infos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParamsInfosComponent {
  versionBo?: string;

  constructor(private versionService: VersionService, private router: Router, private authService: AuthService) {}

  async refresh(): Promise<void> {
    await this.router.navigate(['/']);
    window.location.reload();
  }

  get version(): string {
    return this.versionService.version;
  }

  deconnexion(): void {
    this.authService.deauthenticate(true);
  }
}
