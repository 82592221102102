import { z } from 'zod';
import { Entity, Property } from '../decorators';
import transformer from './transformer';
import { zDateOptionnal } from '../zod-types';

@Entity
export class ReportingSessionModel {
  @Property(zDateOptionnal)
  sessionDateTime!: Date;

  @Property(z.string())
  movieTitle!: string;

  @Property(z.number())
  admits!: number;
}

export const ReportingSessionTransformer = transformer(ReportingSessionModel);
