import { Component, Input } from '@angular/core';
import { ParametersService } from '../../services';
import { Parametres } from 'src/environments/parametres';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Roles } from '@pathe-payment/core';

@Component({
  selector: 'ppa-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  @Input() typeRecherche!: string;
  typeRapport$ = this.parametersService.get$(Parametres.TYPE_RAPPORT) as Observable<string>;
  Roles = Roles;

  constructor(private parametersService: ParametersService, private router: Router) {}

  toggleRechercheSerieCode(valeur: string): void {
    if (valeur === 'code') {
      this.parametersService.set(Parametres.TYPE_RECHERCHE, 'serie');
    } else {
      this.parametersService.set(Parametres.TYPE_RECHERCHE, 'code');
    }
  }

  toggleRapportTransactionPartenaire(valeur: string) {
    if (valeur === 'partenaires') {
      this.parametersService.set(Parametres.TYPE_RAPPORT, 'transactions');
    } else {
      this.parametersService.set(Parametres.TYPE_RAPPORT, 'partenaires');
    }
  }
}
