import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IGlobalStateService, NotificationService } from '../services';
import { Countries } from '../models';

export type ErrorDetail = {
  message: string;
  status: number;
  timestamp: Date;
};

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService, private router: Router, private globalStateService: IGlobalStateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        console.log(`************************* HttpError *************************`);
        console.log(`${err.message}`);
        console.log(err);
        console.log(`*************************************************************`);

        const errorMessage = ((err?.error ?? err) as ErrorDetail)?.message;

        this.notificationService.showError(errorMessage);
        if (
          Object.values(Countries).some((country) => errorMessage.includes(country)) &&
          ((err?.url ?? '').includes('vouchers') || (err?.url ?? '').includes('cards') || (err?.url ?? '').includes('gift-card'))
        ) {
          void this.router.navigate(['/']);
        }

        this.globalStateService.setLoading(false);

        return throwError(err);
      }),
    );
  }
}
