import { Injectable } from '@angular/core';
import { distinctUntilChanged, ReplaySubject, shareReplay } from 'rxjs';

@Injectable()
export class OrientationService {
  private orientationSubject$ = new ReplaySubject<OrientationType>(1);

  public readonly orientation$ = this.orientationSubject$.pipe(distinctUntilChanged(), shareReplay(1));

  constructor() {
    if (screen.orientation) {
      this.orientationSubject$.next(screen.orientation.type);
      screen.orientation.onchange = () => this.orientationSubject$.next(screen.orientation.type);
    } else {
      // Fallback pour Safari
      const mediaQuery = window.matchMedia('(orientation: portrait)');
      const checkMediaQuery = () => this.orientationSubject$.next(mediaQuery.matches ? 'portrait-primary' : 'landscape-primary');

      checkMediaQuery();
      mediaQuery.addEventListener('change', () => checkMediaQuery());
    }
  }
}
