import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Directive({
  selector: '[pclDisplayForRoute]',
})
export class DisplayForRouteDirective implements OnDestroy {
  private hasView = false;
  private routeSubscription!: Subscription;

  @Input() set pclDisplayForRoute(routesArray: string[]) {
    this.routeSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => this.stripLangFromUrl((event as NavigationEnd).urlAfterRedirects)),
        startWith(this.stripLangFromUrl(this.router.url)),
      )
      .subscribe((urlWithoutLang) => {
        const shouldDisplay = routesArray.some((route) => urlWithoutLang === route || urlWithoutLang.startsWith(`${route}/`));
        this.toggleView(shouldDisplay);
      });
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private router: Router) {}

  private stripLangFromUrl(url: string): string {
    return url.replace(/^\/[a-z]{2}(\/|$)/, '/');
  }

  private toggleView(shouldDisplay: boolean): void {
    if (shouldDisplay && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!shouldDisplay && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
