import { Directive, Input, OnInit, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { IUserService } from '../services';
import { Role } from '../models';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[pclRoleAccess]',
})
export class RoleAccessDirective implements OnInit, OnDestroy {
  @Input('pclRoleAccess') roles: Role[] = [];
  @Input('pclRoleAccessMode') mode: 'show' | 'hide' = 'show';

  private destroy$ = new Subject<void>();

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private userService: IUserService) {}

  ngOnInit(): void {
    this.updateVisibility();
  }

  private updateVisibility(): void {
    this.getUserRole()
      .pipe(
        map((currentRole) => this.roles.includes(currentRole)),
        takeUntil(this.destroy$),
      )
      .subscribe((hasRole) => {
        this.viewContainer.clear();
        if ((this.mode === 'show' && hasRole) || (this.mode === 'hide' && !hasRole)) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      });
  }

  private getUserRole(): Observable<Role> {
    return this.userService.me().pipe(
      map((user) => user.role),
      takeUntil(this.destroy$),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
