import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConsumptionData, IVouchersHttpService } from '../interfaces';
import { firstValueFrom } from 'rxjs';
import {
  VoucherCancellationResponseBack,
  VoucherConsumptionResponseBack,
  VoucherHistoryResponseBack,
  VoucherResponseBack,
  SessionsResponseBack,
  VoucherBySerialNumberResponseBack,
  VoucherBySerialNumberSearchParams,
  GiftCardResponseBack,
} from '../interfaces/types';
import { API_DEFAULT_PARAMS } from '../constants';

@Injectable()
export class VouchersHttpService implements IVouchersHttpService {
  constructor(protected httpClient: HttpClient) {}

  getVoucher(country: string, numCard: string): Promise<VoucherResponseBack> {
    return firstValueFrom(this.httpClient.get<VoucherResponseBack>(`/vouchers/${numCard}`, { params: { country } }));
  }

  getVoucherHistory(country: string, numCard: string): Promise<VoucherHistoryResponseBack> {
    return firstValueFrom(this.httpClient.get<VoucherHistoryResponseBack>(`/vouchers/${numCard}/history`, { params: { country } }));
  }

  getVoucherSessions(country: string, numCard: string): Promise<SessionsResponseBack> {
    return firstValueFrom(this.httpClient.get<SessionsResponseBack>(`/vouchers/${numCard}/sessions`, { params: { country } }));
  }

  consumeVoucher(country: string, numCard: string, data: ConsumptionData): Promise<VoucherConsumptionResponseBack> {
    const headers = new HttpHeaders().set('Skip-Error-Interceptor', 'true');
    const params = { country, ...API_DEFAULT_PARAMS };

    return firstValueFrom(this.httpClient.post<VoucherConsumptionResponseBack>(`/vouchers/${numCard}/consume`, data, { params, headers }));
  }

  cancelVoucherOperation(
    country: string,
    numCard: string,
    consumeTransactionId: number,
    salesChannel: string,
  ): Promise<VoucherCancellationResponseBack> {
    const headers = new HttpHeaders().set('Skip-Error-Interceptor', 'true');
    const params = { country, consumeTransactionId, salesChannel, ...API_DEFAULT_PARAMS };

    return firstValueFrom(this.httpClient.post<VoucherCancellationResponseBack>(`/vouchers/${numCard}/cancel`, {}, { params, headers }));
  }

  getVoucherBySerialNumber(
    country: string,
    type: string,
    serialNumberStart: number,
    serialNumberEnd?: number,
  ): Promise<VoucherBySerialNumberResponseBack> {
    let params: VoucherBySerialNumberSearchParams = { country, type, serialNumberStart };
    if (serialNumberEnd !== undefined) {
      params = { ...params, serialNumberEnd };
    }
    return firstValueFrom(this.httpClient.get<VoucherBySerialNumberResponseBack>(`/vouchers`, { params }));
  }

  getGiftCard(country: string, numCard: string, pin: number): Promise<GiftCardResponseBack> {
    return firstValueFrom(this.httpClient.get<VoucherResponseBack>(`/gift-card/${numCard}`, { params: { country, pin } }));
  }
}
