import { ModuleWithProviders, NgModule } from '@angular/core';

import { ButtonModule } from './button';
import { CardModule } from './card';
import { CollapsibleModule } from './collapsible';
import { FormModule } from './form';
import { GaugeModule } from './gauge';
import { LoaderModule } from './loader';
import { ModalModule } from './modal';
import { providers as ModalModuleProviders } from './modal/providers';
import { OrientationModule } from './orientation';
import { providers as OrientationModuleProviders } from './orientation/providers';
import { ProgressModule } from './progress';
import { TableModule } from './table';
import { ToolbarModule } from './toolbar';
import { providers as ToolbarModuleProviders } from './toolbar/providers';

@NgModule({
  imports: [
    ButtonModule,
    CardModule,
    FormModule,
    GaugeModule,
    LoaderModule,
    ModalModule,
    ProgressModule,
    OrientationModule,
    TableModule,
    ToolbarModule,
    CollapsibleModule,
  ],
  exports: [
    ButtonModule,
    CardModule,
    FormModule,
    GaugeModule,
    LoaderModule,
    ModalModule,
    ProgressModule,
    OrientationModule,
    TableModule,
    ToolbarModule,
    CollapsibleModule,
  ],
})
export class UiModule {
  static forRoot(): ModuleWithProviders<UiModule> {
    return {
      ngModule: UiModule,
      providers: [...ModalModuleProviders, ...OrientationModuleProviders, ...ToolbarModuleProviders],
    };
  }
}
