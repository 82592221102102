import { CardCancellationResponseBack, CardConsumptionResponseBack, CardResponseBack, SessionsResponseBack } from './types';
import { ConsumptionData } from './vouchers-http.service';

export abstract class ICardsHttpService {
  abstract getCard(country: string, numCard: string): Promise<CardResponseBack>;
  abstract consumeCard(country: string, numCard: string, data: ConsumptionData): Promise<CardConsumptionResponseBack>;
  abstract getCardSessions(country: string, numCard: string): Promise<SessionsResponseBack>;
  abstract cancelCardOperation(
    country: string,
    numCard: string,
    transactionNumber: number,
    salesChannel: string,
  ): Promise<CardCancellationResponseBack>;
}
