import { Injectable } from '@angular/core';
import { ConsumptionData, IVouchersHttpService } from '../../http';
import {
  SessionsTransformer,
  VoucherCancellationModel,
  VoucherConsumptionModel,
  VoucherHistoryModel,
  VoucherHistoryTransformer,
  VoucherModel,
  SessionsModel,
  VoucherTransformer,
  VoucherBySerialNumberModel,
  VoucherBySerialNumberTransformer,
  GiftCardModel,
  GiftCardTransformer,
} from '../../../models';
import { ICurrentCodeService, IVouchersService } from '../interfaces';

@Injectable()
export class VouchersService implements IVouchersService {
  private lastVoucher?: VoucherModel;
  private lastGiftCard?: GiftCardModel;

  constructor(private vouchersHttpService: IVouchersHttpService, private currentCodeService: ICurrentCodeService) {}

  async getVoucher(country: string, numCard: string): Promise<VoucherModel> {
    const voucherBack = await this.vouchersHttpService.getVoucher(country, numCard);

    const voucher = VoucherTransformer.parse(voucherBack);
    this.lastVoucher = voucher;
    this.currentCodeService.setCurrentCode(voucher.code);
    return voucher;
  }

  async getVoucherHistory(country: string, numCard: string): Promise<VoucherHistoryModel> {
    this.currentCodeService.setCurrentCode(numCard);
    const voucherHistoryBack = await this.vouchersHttpService.getVoucherHistory(country, numCard);

    const voucherHistory = VoucherHistoryTransformer.parse(voucherHistoryBack);
    return voucherHistory;
  }

  async getVoucherSessions(country: string, numCard: string): Promise<SessionsModel> {
    this.currentCodeService.setCurrentCode(numCard);
    const sessionsBack = await this.vouchersHttpService.getVoucherSessions(country, numCard);

    const sessions = SessionsTransformer.parse(sessionsBack);
    return sessions;
  }

  updateLastVoucher(nbTicketsRemaining: number): void {
    if (this.lastVoucher) {
      this.lastVoucher.numberOfSeatsRemaining = nbTicketsRemaining;
    }
  }

  async getLastVoucher(country: string, voucherCode: string): Promise<VoucherModel> {
    if (this.lastVoucher?.code === voucherCode) {
      return this.lastVoucher;
    } else {
      return this.getVoucher(country, voucherCode);
    }
  }

  async consumeVoucher(country: string, numCard: string, data: ConsumptionData): Promise<VoucherConsumptionModel> {
    return this.vouchersHttpService.consumeVoucher(country, numCard, data);
  }

  async cancelVoucherOperation(
    country: string,
    numCard: string,
    transactionNumber: number,
    salesChannel: string,
  ): Promise<VoucherCancellationModel> {
    return this.vouchersHttpService.cancelVoucherOperation(country, numCard, transactionNumber, salesChannel);
  }

  async getVoucherBySerialNumber(
    country: string,
    type: string,
    serialNumberStart: number,
    serialNumberEnd?: number,
  ): Promise<VoucherBySerialNumberModel> {
    const voucherBySerialNumberBack = await this.vouchersHttpService.getVoucherBySerialNumber(
      country,
      type,
      serialNumberStart,
      serialNumberEnd,
    );
    const voucherBySerialNumber = VoucherBySerialNumberTransformer.parse(voucherBySerialNumberBack);
    if (!serialNumberEnd) {
      this.lastVoucher = {
        ...voucherBySerialNumber.vouchers[0],
        type: voucherBySerialNumber.type,
        typeDescription: voucherBySerialNumber.typeDescription,
      };
      this.currentCodeService.setCurrentCode(this.lastVoucher.code);
    }
    return voucherBySerialNumber;
  }

  async getGiftCard(country: string, numCard: string, pin: number): Promise<GiftCardModel> {
    const giftCardBack = await this.vouchersHttpService.getGiftCard(country, numCard, pin);
    const giftCard = GiftCardTransformer.parse(giftCardBack);
    this.lastGiftCard = giftCard;
    return giftCard;
  }

  getLastGiftCard(code: string): GiftCardModel | null {
    if (this.lastGiftCard?.code === code) {
      return this.lastGiftCard;
    } else {
      return null;
    }
  }
}
