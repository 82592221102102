import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';

import { AuthService, IUserService } from '../services';
import { CORE_CONFIG } from '../tokens';
import { CoreConfig } from '../core.config';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class CanActivateIfAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router,
    @Inject(CORE_CONFIG) private config: CoreConfig,
    private userService: IUserService,
  ) {}

  async canActivate() {
    if (await this.authService.isAuthenticated) {
      try {
        if (await firstValueFrom(this.userService.me())) {
          return true;
        }
      } catch (e: any) {
        void this.authService.deauthenticate();
        await this.router.navigate(this.config.authRoute, {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          state: { error: (e?.error?.message as string) ?? "Vous n'avez pas accès à cette application" },
        });
      }
    }

    return this.router.createUrlTree(this.config.authRoute);
  }

  canActivateChild() {
    return this.canActivate();
  }
}
