import {
  VoucherModel,
  VoucherHistoryModel,
  VoucherConsumptionModel,
  VoucherCancellationModel,
  SessionsModel,
  GiftCardModel,
} from '../../../models';
import { VoucherBySerialNumberModel } from '../../../models/voucher-by-serial-number.model';
import { ConsumptionData } from '../../http';

export abstract class IVouchersService {
  abstract getVoucher(country: string, numCard: string): Promise<VoucherModel>;
  abstract getVoucherHistory(country: string, numCard: string): Promise<VoucherHistoryModel>;
  abstract getVoucherSessions(country: string, numCard: string): Promise<SessionsModel>;
  abstract updateLastVoucher(nbTicketRemaining: number): void;
  abstract getLastVoucher(country: string, voucherCode: string, redownload?: boolean): Promise<VoucherModel>;
  abstract consumeVoucher(country: string, numCard: string, data: ConsumptionData): Promise<VoucherConsumptionModel>;
  abstract cancelVoucherOperation(
    country: string,
    numCard: string,
    transactionNumber: number,
    salesChannel: string,
  ): Promise<VoucherCancellationModel>;
  abstract getVoucherBySerialNumber(
    country: string,
    type: string,
    serialNumberStart: number,
    serialNumberEnd?: number,
  ): Promise<VoucherBySerialNumberModel>;
  abstract getGiftCard(country: string, numCard: string, pin: number): Promise<GiftCardModel>;
  abstract getLastGiftCard(code: string): GiftCardModel | null;
}
