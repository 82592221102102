import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { CoreConfig } from './core.config';
import { CORE_CONFIG, ERROR_SIMULATION_CONFIG } from './tokens';

import * as Interceptors from './interceptors';
import * as Services from './services';
import * as Guards from './guards';
import * as Directives from './directives';

import { environment } from 'src/environments/environment';

@NgModule({
  imports: [MatSnackBarModule],
  declarations: [Directives.RoleAccessDirective, Directives.DisplayForRouteDirective],
  exports: [Directives.RoleAccessDirective, Directives.DisplayForRouteDirective],
})
export class CoreModule {
  static forRoot(config: CoreConfig): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: CORE_CONFIG, useValue: config },
        { provide: HTTP_INTERCEPTORS, useClass: Interceptors.ApiInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: Interceptors.ErrorsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: Interceptors.SimulateErrorsInterceptor, multi: true },
        {
          provide: ERROR_SIMULATION_CONFIG,
          useValue: { enabled: !environment.production },
        },
        { provide: Services.AuthService, useClass: config.authService },
        { provide: Services.ICardsHttpService, useClass: Services.CardsHttpService },
        { provide: Services.ICardsService, useClass: Services.CardsService },
        { provide: Services.ICurrentCodeService, useClass: Services.CurrentCodeService },
        { provide: Services.IVouchersHttpService, useClass: Services.VouchersHttpService },
        { provide: Services.IVouchersService, useClass: Services.VouchersService },
        { provide: Services.IReportingHttpService, useClass: Services.ReportingHttpService },
        { provide: Services.IReportingService, useClass: Services.ReportingService },
        { provide: Services.IRoleOverrideService, useClass: Services.RoleOverrideService },
        { provide: Services.IUserService, useClass: Services.UserService },
        { provide: Services.IUserHttpService, useClass: Services.UserHttpService },
        { provide: Services.IGlobalStateService, useClass: Services.GlobalStateService },
        Services.CacheService,
        Services.NotificationService,
        Guards.CanActivateIfNotAuthGuard,
        Guards.CanActivateIfAuthGuard,
        Guards.CanActivateIfRoleGuard,
      ],
    };
  }
}
