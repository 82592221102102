import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Components from './components';

@NgModule({
  imports: [CommonModule],
  declarations: [Components.LoaderComponent, Components.SpinnerComponent],
  exports: [Components.LoaderComponent, Components.SpinnerComponent],
})
export class LoaderModule {}
