import { EmbeddedViewRef, Injectable, TemplateRef } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export type ToolbarItemInfos = { embeddedView: EmbeddedViewRef<void>; index: number };

@Injectable()
export class ToolbarService {
  private views = new Map<TemplateRef<void>, ToolbarItemInfos>();
  private viewsSubject$ = new ReplaySubject<ToolbarItemInfos[]>(1);

  readonly views$ = this.viewsSubject$.asObservable();

  add(templateRef: TemplateRef<void>, index: number) {
    this.views.set(templateRef, { embeddedView: templateRef.createEmbeddedView(), index });
    this.triggerUpdate();
  }

  setIndex(templateRef: TemplateRef<void>, index: number) {
    const view = this.views.get(templateRef);
    if (view) {
      this.views.set(templateRef, { ...view, index });
      this.triggerUpdate();
    }
  }

  remove(templateRef: TemplateRef<void>) {
    const view = this.views.get(templateRef);
    if (view) {
      view.embeddedView.destroy();
      this.views.delete(templateRef);
      this.triggerUpdate();
    }
  }

  private triggerUpdate() {
    this.viewsSubject$.next([...this.views.values()]);
  }
}
